<template>
  <edit-template type="card" v-loading="loading1">
    <div class="top-info p-3">
      <LineWrapper class="list1" primary>
        <LineItem :label="item.label" :value="item.value" :color="item.color" v-for="item in list1" :key="item.label"></LineItem>
      </LineWrapper>
      <div>
        <el-button type="primary" @click="handleView">查看审批记录</el-button>
      </div>
    </div>
    <div class="p-3">
      <LineWrapper class="list2" primary>
        <LineItem :label="item.label" :value="item.value" v-for="(item, index) in list2" :key="index"></LineItem>
      </LineWrapper>
      <div class="mb-3"></div>
      <DetailTable border :data="tableData1" :config="tableConfig1"></DetailTable>
    </div>
    <Dialog :visible.sync="visible" :loading="loading2" size="big" title="查看审批记录" submitText="我知道了" buttonAlign="center" :showCancelButton="false">
      <DetailTable border :data="tableData2" :config="tableConfig2"></DetailTable>
    </Dialog>
    <template #footer>
      <el-button @click="$router.back()">返回</el-button>
    </template>
  </edit-template>
</template>

<script>
import Dialog from "@/components/common/Dialog"
import LineWrapper from "@/components/common/LineWrapper"
import LineItem from "@/components/common/LineItem"
import DetailTable from "@/components/common/DetailTable"
import { zsApproveStatusColor } from "@/utils/data"
import { zsGet } from "@/api"
export default {
	_config:{"route":{"path":"detail","meta":{"title":"详情"}}},
  components: { Dialog, LineWrapper, LineItem, DetailTable },
  data() {
    return {
      visible: false,
      loading1: false,
      loading2: false,
      isLoaded: false,
      list1: [],
      list2: [],
      tableConfig1: [
        { prop: 'school_year_name', label: '收费学年' },
        { prop: 'charge_date', label: '收费日期' },
        { prop: 'fee_combination_name', label: '科目组合名称' },
        { prop: 'payable_price', label: '应付金额' },
        { prop: 'reduction_price', label: '减免金额' },
        { prop: 'actual_price', label: '实付金额' }
      ],
      tableConfig2: [
        { prop: 'approver', label: '审批人' },
        { prop: 'updated_at', label: '审批时间' },
        { prop: 'approve_status_text', label: '审批结果' },
        { prop: 'remark', label: '备注说明' }
      ],
      tableData1: [],
      tableData2: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading1 = true
      zsGet('/finance/fee-stu/show', { id: this.$route.query.id }).then(res => {
        const { student_name, id_card, approve_status_text, fee_type_text, actual_amount, fee_plan_id, fee_plan: { mode_text, category_text, name} } = res
        this.list1 = [{ label: '学生姓名', value: student_name }, { label: '学生身份证号', value: id_card }, { label: '审核状态', value: approve_status_text, color: zsApproveStatusColor(approve_status_text) },]
        this.list2 = [{ label: '生活费类型', value: fee_type_text }, { label: '方案分类', value: category_text }, { label: '缴费方式', value: mode_text }, { label: '收费方案', value: name }, { label: '总金额', value: actual_amount }]

        zsGet('/finance/fee-stu/get-plan', { fee_plan_id }).then(res => {
          const { combination = [] } = res
          this.tableData1 = combination
        }).finally(() => this.loading1=false)
      }).catch(() => this.loading1=false)
    },
    getRecord() {
      if(this.isLoaded) return
      this.loading2 = true
      zsGet('/finance/fee-stu/approve-record', { fee_student_id: this.$route.query.id }).then(res => {
        this.tableData2 = res
        this.isLoaded = true
      }).finally(() => this.loading2=false)
    },
    handleView() {
      this.visible = true
      this.getRecord()
    }
  },
}
</script>


<style lang="scss" scoped>
.top-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20rem 100rem;
  border-bottom: 20rem solid #f7f7f7;
}
.list1 {
  margin: -10rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 20rem;
}
.list2 {
  margin: -10rem 0;
  display: grid;
  gap: 0 20rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>
